.banner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.banner__img {
  width: 100%;
  height: auto;
}

.banner__img--desktop {
  display: none;
  visibility: hidden;
  aspect-ratio: 2.23 / 1;
}

.banner__img--mobile {
  display: block;
  visibility: visible;
  aspect-ratio: 214 / 339;
}

.banner__text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.banner__text-bold {
  font-weight: 700;
  /* font-size: 35px; */
  font-size: 30px;
  display: block;
  font-family: "Comfortaa";
  padding: 0 1rem;
}

.banner__text-normal {
  font-weight: 600;
  font-size: 18px;
  font-family: "Comfortaa";
  line-height: 1.3;
}

.banner__btn-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.banner__text-html {
  font-size: 17px;
  margin-top: 20px;
  display: block;
  color: #707070;
}

.font__open-sans {
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
}

.home__header-span {
  visibility: hidden;
}

.banner__subtitle {
  font-weight: 600;
  color: #707070;
  font-size: 12px;
}

@media screen and (min-width: 600px) {
  span.banner__text-bold {
    /* font-size: 45px; */
    font-size: 38px;
  }

  span.banner__text-normal {
    font-size: 23px;
  }

  /* .banner__subtitle {
    font-size: 14px;
  } */
}

@media screen and (min-width: 900px) {
  .banner__img--desktop {
    display: block;
    visibility: visible;
  }

  .banner__img--mobile {
    display: none;
    visibility: hidden;
  }

  .banner__img {
    width: 100%;
  }

  span.banner__text-bold {
    font-weight: 700;
    display: block;
    padding: 0;
  }

  span.banner__text-normal {
    font-weight: 600;
    font-size: 25px;
  }

  .banner__btn-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }

  .banner__text-html {
    font-size: 20px;
    margin-top: 20px;
    max-width: 600px;
  }

  .banner__text {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: left;
    padding: 0px 70px 0px 70px;
  }

  .banner__subtitle {
    display: block;
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .banner__btn-container {
    margin-top: 40px;
  }

  span.banner__text-bold {
    font-weight: 700;
    /* font-size: 55px; */
    font-size: 45px;
    display: block;
  }

  span.banner__text-normal {
    font-weight: 300;
    font-size: 26px;
  }

  .banner__text {
    padding: 0px 120px 0px 120px;
  }

  .banner__subtitle {
    font-size: 16px;
  }
}

@media (min-width: 1800px) {
  span.banner__text-bold {
    font-weight: 700;
    /* font-size: 64px; */
    font-size: 60px;
  }

  span.banner__text-normal {
    font-size: 32px;
  }

  .banner__subtitle {
    font-size: 18px;
  }
}
