.findSurgeon__div-discretion {
  margin-bottom: 3rem;
}

.findSurgeon__div-discretion .paragraph.findSurgeon__paragraph {
  font-size: 12px;
  margin: 5px 0px 5px 0px;
}

.findSurgeon__anchor-email {
  color: #365b7e;
}

.findSurgeon .banner .banner__text .banner__text-normal {
  font-size: 35px;
}

.findSurgeon__div-filter {
  margin-bottom: 2rem;
  position: relative;
  /* width: 290px; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}

select:focus {
  outline-color: #365b7e;
}

select {
  padding: 5px 5px 5px 30px;
  color: #373d43;
  appearance: none;
  border-radius: 3px;
  /* width: 300px; */
  width: 100%;
  height: 38px;
  line-height: 22px;
  border: 1px solid #dee0e2;
  font-size: 14px;
}

.map-pin-icon {
  font-size: 20px;
  vertical-align: middle;
}

.findSurgeon__span-icon {
  margin-left: 10px;
  position: absolute;
  left: 0%;
}

.findSurgeon__span-icon-1 {
  display: inline-block;
  margin-right: 10px;
}

.error {
  color: #faa21e;
  font-size: 14px;
}

.search-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-container {
  display: flex;
  flex-direction: row;
  line-height: 30px;
  margin: 20px auto;
  width: 100%;
  justify-content: center;
}

.geoapify-autocomplete-input {
  width: 251px !important;
}

.geoapify-autocomplete-items {
  height: auto;
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
}

.address {
  font-size: 11px;
  line-height: 20px;
}

.custom-filter-button {
  position: absolute;
  margin-right: 10px;
  right: 0%;
  pointer-events: none;
}

.custom-clear-button {
  position: absolute;
  margin-right: 5px;
  right: -1%;
  width: 20px;
}

/* .fas.fa-times {
  font-size: 18px;
} */

.fas.fa-times:hover,
.custom-clear-button:hover {
  cursor: pointer;
}

/* input[type="text"]::-webkit-calendar-picker-indicator {
  display:none !important;
} */

@media screen and (min-width: 450px) {
  .geoapify-autocomplete-input {
    width: 328px !important;
  }

  .address {
    font-size: 14px;
  }
}

@media screen and (min-width: 600px) {
  .findSurgeon__div-discretion {
    margin-bottom: 3rem;
  }

  .findSurgeon .banner .banner__text .banner__text-normal {
    font-size: 45px;
  }
  .findSurgeon__div-filter {
    width: 300px;
  }
  select {
    width: 300px;
  }

  .geoapify-autocomplete-input {
    width: 376px !important;
  }

  .address {
    font-size: 14px;
  }
}

@media screen and (min-width: 900px) {
  .findSurgeon__div-discretion .paragraph.findSurgeon__paragraph {
    font-size: 16px;
  }

  .findSurgeon .banner .banner__text .banner__text-normal {
    font-size: 50px;
  }

  .findSurgeon .banner .banner__text {
    top: 50%;
    left: 50%;
    text-align: left;
  }

  .findSurgeon__div-discretion,
  .findSurgeon__div-filter--wrapper {
    padding: 0px 45px;
  }

  .map-pin-icon {
    font-size: 21px;
  }

  .findSurgeon__div-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .findSurgeon__div-row--last {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .search-container {
    width: 65%;
  }
}

@media screen and (min-width: 1200px) {
  .findSurgeon__div-row {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  .findSurgeon .banner .banner__text .banner__text-normal {
    font-size: 64px;
  }

  .findSurgeon .banner .banner__text {
    left: 55%;
  }

  .findSurgeon__div-discretion,
  .findSurgeon__div-filter--wrapper {
    padding: 0px 40px;
  }

  .findSurgeon__div-row--last {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  .search-container {
    width: 48%;
  }

  .geoapify-autocomplete-input {
    width: 400px !important;
  }

  .address {
    font-size: 14px;
  }
}

@media screen and (min-width: 1500px) {
  .search-container {
    width: 50%;
  }

  .geoapify-autocomplete-input {
    width: 450px !important;
  }
}

@media screen and (min-width: 1800px) {
  .findSurgeon .banner .banner__text .banner__text-normal {
    font-size: 75px;
  }

  .findSurgeon__div-discretion,
  .findSurgeon__div-filter--wrapper {
    padding: 0px 60px;
  }

  .findSurgeon__div-row {
    margin-bottom: 1.5rem;
  }
}
